import Pusher from "pusher-js";

let pusher = null;

export default {
  getInstance: getInstance,
  init: init,
  subscribe: subscribe,
  listen: listen,
  unsubscribe: unsubscribe,
  dispose: dispose,
};

function getInstance() {
  return pusher;
}

function init() {
  pusher = {
    instance: null,
    channels: {},
  };

  pusher.instance = new Pusher(process.env.VUE_APP_PUSHER_APP_KEY, {
    cluster: process.env.VUE_APP_PUSHER_CLUSTER,
    // authEndpoint: process.env.VUE_APP_API_BASE_URL + "/api/auth/pusher",
    // auth: {
    //   params: { deviceId: keyCode },
    // },
  });
}

function subscribe(channelName) {
  pusher.channels[channelName] = pusher.instance.subscribe(channelName);
}

function listen(channelName, eventName, callback) {
  pusher.channels[channelName].bind(eventName, callback);
}

function unsubscribe(name) {
  pusher.instance.unsubscribe(name);
}

function dispose(channelName) {
  if (channelName) {
    unsubscribe(channelName);
  } else {
    var keys = Object.keys(pusher.channels);
    keys.forEach((key) => {
      var c = pusher.channels[key];
      unsubscribe(c.name);
    });
  }
}
